import React from 'react';
import { Layout } from '../../components/Layout';

const CalgaryRrprWindowWellsAirConditioning = () => (
  <Layout
    title="RPR, Real property Report, blog entry with detailed referenced information on Air Conditioning and window wells survey requirements"
    description="Detailed blog entry discussing issues with RPR, Real Property Reports relating to Window Wells and Air Conditioning units and municipal bylaws."
    keywords=""
  >
    <main>
      <h1>
        Calgary Real Property Reports, Window Wells & Air Conditioning Units: What You Need To Know
      </h1>

      <p>
        In 2008, the City of Calgary updated Land Use Bylaw 1P2007. Since then, a significant number
        of our clients have struggled with the new regulations regarding window wells and air
        conditioning (A/C) units.
      </p>

      <p>
        Before 2008, there were effectively no Calgary RPR bylaw issues concerning either window
        wells or A/C units. Now, due to the changes there are 3 situations we should be aware of
        that may cause concern when applying for a stamp of compliance at the City of Calgary.
      </p>

      <p>
        Firstly: Air conditioning units (A/C units) are now only allowed to encroach, or project, up
        to 1.0 meters into the required side yard (the area at the side of your property). As the
        side yard is typically 1.2 meters this means that a air conditioning unit needs to be at
        least .2 from the side of your property. This comes from land use bylaw 1p2007 Section
        337-10
      </p>

      <p>
        Secondly: In a similar way to air conditioning unit bylaws, window wells are allowed to
        encroach up to 0.8 meters into the side yard. Assuming the typical 1.2 meter side yard this
        means that your window wells must be at least .4 from the side property line. This
        information comes from Section 337-1.2 of the same bylaw.
      </p>

      <p>
        Thirdly: At least one side of your property must be clear of projections by 1.2 meters.
        These projections include A/C units, window wells, and cantilevers. This comes from both
        Section 337 - 10 and Section 337-1.2 of the Land use bylaw.
      </p>

      <h2>Why should this matter to you?</h2>

      <p>
        When you buy or sell a house, you will want to know if there are any bylaws that it does not
        follow. If the house does not comply with local bylaws, you will not get a stamp of
        compliance from the City of Calgary. Many buyers will not accept a house without this stamp,
        and almost all mortgage companies will not mortgage a property with these deficiencies.
      </p>

      <p>
        When you buy that property, you are about to inherit any problems the property has. If your
        new home does not comply with Calgary’s bylaws, the city may then require you to fix this
        situation, which will add additional expenses to your new home. Every buyer should get a
        Real Property Report (RPR) to make sure that their new home will have none of these issues.
      </p>

      <h2>Consequences for noncompliant window wells</h2>

      <p>
        Although rare, if the city makes you remove a window well, you will likely have to remove
        the window. Because basement bedrooms are required to have a window of a certain size for
        escape in case of an emergency, that room will no longer be able to be used as a bedroom.
        This may cause serious frustration for a growing family, for example, who must find other
        rooms for a permanent bedroom, on top of the costs of removing the window well.
      </p>

      <h2>Consequences for noncompliant air conditioning units</h2>

      <p>
        If the city requires that you relocate your air conditioning unit to the back of the house,
        you will have to face the expense of having it decommissioned, moved and reinstalled.
        Because the connection between the furnace and the exterior A/C unit is usually run through
        the floor, you will face the extra costs of pulling down the ceiling and moving these
        connections around. Even if your basement is unfinished, it may cause frustrations because
        the new required outside location may not work very well with the inside design of the
        basement. This is an expense that no homeowner, especially a new homeowner, wants to face.
      </p>

      <h2>What about window wells and A/C units installed before 2008?</h2>

      <p>
        There is a grandfather process that allows Calgary homeowners to keep any window wells and
        air conditioning units installed before July 1, 2008. However, in this case, the city
        considers you guilty until proven innocent. It will be assumed that the projections did not
        exist prior to 2008 unless you are able to prove otherwise.
      </p>

      <p>Acceptable forms of proof are:</p>

      <ul>
        <li>
          An official invoice or receipt from the installer showing the date of the installation
          (not the purchasing date)
        </li>
        <li>
          The builder’s purchase agreement, with record of the window well or A/C installation
        </li>
      </ul>

      <h2>How Do we Fix this?</h2>

      <p>
        When buying or selling a house, it is crucial that the buyer or seller has an understanding
        of any issues with the property and what the implications of these issues are. A Real
        Property Report may inform homeowners of these issues. Typically if these issues are
        discovered, we will have to apply for a relaxation to make the existing design legal. There
        will be an upcoming post dedicated to this topic.
      </p>

      <p>
        At Axiom Geomatics, issues like this affect about 10% of the properties we survey. Because
        this is such a common problem, we can usually anticipate and warn our client about the risk
        even before the survey has been issued. Axiom Geomatics has produced many Real Property
        Reports and we enjoy helping homeowners become informed about issues such as these. An RPR
        will bring awareness to many situations that may exist with your property but it is really
        just the start of the conversation. We hope this article will cause you to have more
        discussions about land, and Real Property Reports, and if we can be part of that
        conversation we we welcome that opportunity.
      </p>
    </main>
  </Layout>
);

export default CalgaryRrprWindowWellsAirConditioning;
